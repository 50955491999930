import { useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import { useDispatch, useSelector } from "react-redux";
import { fetchUser, logout } from "../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MenuDropdown from "./MenuDropDown";
import { fileURL, getPathByMaster } from "../utils/helpers";
import { validateResponse } from "../network/apiCalls";
const SiteHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const websiteData = useSelector((state) => state.websiteData);
  const menuItems = [
    { title: "Home", path: "/" },
    {
      title: "Games",
      childs: websiteData.masterCategory.map((item) => {
        return { title: item.cname, path: getPathByMaster(item), image: item.image };
      }),
    },
  ];
  const hideMobileMenu = () => {
    $("body").removeClass("mobile-menu-visible");
  };
  useEffect(() => {
    if(auth.userToken != null){
      fetchUpdatedUser();
    }
    if ($(".mobile-menu").length) {
      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(300);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      // //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", hideMobileMenu);
    }
    document.getElementById("preloader").style.display = "none";
    
  }, []);

  const fetchUpdatedUser = async () => {
    try{
      let resp = await dispatch(fetchUser()).unwrap()
    }catch(e){
      console.log(e)
    }
  }

  function logoutUser() {
    dispatch(logout());
    toast.success("Logout successfully");
    navigate("/");
  }

  return (
    <>
      <div id="preloader">
        <div className="spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
      <header id="header">
        <div id="header-fixed-height"></div>
        <div
          id="sticky-header"
          className="menu-area shadow"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars"></i>
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <Link to={"/"} className="main-logo">
                        <img
                          src={fileURL("/static/media/logo-cropped.png")}
                          alt="Logo"
                          style={{ height: "103px"}}
                        />
                      </Link>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul id="menu-home-page-menu" className="navigation">
                        {menuItems.map((item, index) => {
                          if (item.childs) {
                            return (
                              <MenuDropdown
                                key={index}
                                title={item.title}
                                items={item.childs}
                                styles={
                                  item.title == "Games"
                                    ? { width: "260px" }
                                    : {}
                                }
                              />
                            );
                          }
                          return (
                            <li
                              key={index}
                              id="menu-item-264"
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-264"
                            >
                              <Link to={item.path} title={item.title}>
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {auth.userToken ? (
                      <div className="d-flex align-items-center">
                        <img onClick={()=>navigate('/profile/userWallet')} className="me-1" width="40" height="40" src={fileURL('/static/icons/digital-wallet.png')} alt="wallet"/>
                        <div className="dropdown">
                          <button
                            className="btn border dropdown-toggle me-2 p-2 d-flex align-items-center"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              width="35"
                              height="35"
                              src={auth.userInfo?.photoURL ? auth.userInfo?.photoURL : fileURL('/static/icons/user.png')}
                              alt="circled-user-male-skin-type-7--v1"
                            />
                            {auth.userInfo.name}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <Link to="/profile" className="dropdown-item">
                                <i className="fa-solid fa-user"></i> Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/profile/userWallet" className="dropdown-item">
                                <i className="fa-solid fa-wallet"></i> Wallet
                              </Link>
                            </li>
                            <li>
                              <Link to="/profile/userOrder" className="dropdown-item">
                                <i className="fa-solid fa-shopping-cart"></i> Orders
                              </Link>
                            </li>
                            <li>
                              <Link to="/profile/userCode" className="dropdown-item">
                                <i className="fa-solid fa-shopping-cart"></i> Codes
                              </Link>
                            </li>
                            <li>
                              <Link to="/profile/userSecurity" className="dropdown-item">
                                <i className="fa-solid fa-lock"></i> Security
                              </Link>
                            </li>
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => logoutUser()}
                              >
                                <i className="fa-solid fa-right-from-bracket"></i>{" "}
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="header-action d-none d-md-block">
                        <ul>
                          <li className="header-btn">
                            <Link
                              className="btn bg-light-green text-white btn-md"
                              to="/register"
                            >
                              Register
                            </Link>
                            <Link
                              className="btn btn-primary btn-md ms-1"
                              to="/login"
                            >
                              Login
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </nav>
                </div>
                {/* <!-- Mobile Menu  --> */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times"></i>
                    </div>
                    <div className="nav-logo"></div>
                    <div className="menu-outer">
                      <ul id="menu-home-page-menu-1" className="navigation">
                        {menuItems.map((item, index) => {
                          if (item.childs) {
                            return (
                              <MenuDropdown
                                key={index}
                                title={item.title}
                                items={item.childs}
                              />
                            );
                          }
                          return (
                            <li
                              key={index}
                              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-264"
                            >
                              <Link to={item.path} title={item.title}>
                                {item.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="d-flex justify-content-between p-3 align-items-center">
                      {auth.userToken ? null: (
                        <>
                          <Link
                            className="btn bg-light-green text-white btn-sm btn-block"
                            to="/register"
                            onClick={hideMobileMenu}
                          >
                            Register
                          </Link>
                          <Link
                            className="btn btn-primary btn-sm btn-block"
                            to="/login"
                            onClick={hideMobileMenu}
                          >
                            Login
                          </Link>
                        </>
                      )}
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop"></div>
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default SiteHeader;
