import { useEffect, useState } from "react";
import SiteHeader from "../components/SiteHeader";
import SiteFooter from "../components/SiteFooter";
import HomePage from "./HomePage";
import ApplyPage from "./ApplyPage";
import LoginPage from "./LoginPage";
import BlogPage from "./BlogPage";
import RegisterPage from "./RegisterPage";

import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlogPostDetails from "./BlogPostDetails";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Category from "./Category";
import ProfilePage from "./Profile";
import TermsConditionPage from "./TermsConditionPage";
import { useDispatch, useSelector } from "react-redux";
import { getMasterCategory } from "../redux/features/websiteSlice";
import UserProfile from "../components/UserProfile";
import Voucher from "./Voucher";
import PageNotFound from "./PageNotFound";
import SiteModals from "../components/Modals";
import {  getShopStatusApi, getWatchUrl } from "../network/apiCalls";
import { fileURL } from "../utils/helpers";
import { setTelegram, setWhatsappNumber } from "../redux/features/authSlice";

const MainSite = () => {
  const auth = useSelector(state=>state.auth)
  const [shopStatus,setShopStatus] = useState(null);

  const disptach = useDispatch();
  const fetchShopStatus =  async () => {
    try{
      let response = await getShopStatusApi()
      if(response.status == 200){
        let data = response.data;
        setShopStatus(data)
      }
    }catch(e){
     console.log(e)
    }
  }
  const fetchWhatsapp = async () => {
    try {
      let response = await getWatchUrl();
      if (response.status == 200 && response.data.status == true) {
        disptach(setWhatsappNumber(response.data.rocket));
        disptach(setTelegram(response.data.nagad));
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };
  useEffect(() => {
    disptach(getMasterCategory());
    fetchShopStatus();
    fetchWhatsapp()
  }, []);
  return (
    <>
      <ScrollToTop />
      <div className="container-fluid bg-light p-2">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <a href={"https://wa.me/+"+auth.whatsappNumber} className="text-black me-3">
                <img src={"/static/icons/WhatsApp_logo.png"} alt="Logo" style={{height:30}}/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <SiteHeader />
      <main className="main-area bg-light pb-5 overflow-hidden">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/category/:id" element={<Category />} />
          <Route path="/voucher/:id" element={<Voucher />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/profile/*" element={<ProfilePage />} />
          <Route path="/contact-us" element={<ContactUs />} />

          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/terms-and-condition" element={<TermsConditionPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <SiteFooter />
      {(shopStatus && shopStatus.popup_status == '1') ? (
        <SiteModals id={'example1'} text={shopStatus.popup_text} image={shopStatus.popup_image} />
      ):null}
      {(shopStatus && shopStatus.popup_status2 == '1') ? (
        <SiteModals id={'example2'} text={shopStatus.popup_text2} image={shopStatus.popup_image2}/>
      ):null}
      <ToastContainer />
      <ScrollToTop/>
    </>
  );
};
export default MainSite;
