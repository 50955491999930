const ContactUs = () => {
  return (
    <main className="main-area mb-5">
      <div
        data-elementor-type="wp-page"
        data-elementor-id="57"
        className="elementor elementor-57"
      >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-948c8fc elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      data-id="948c8fc"
      data-element_type="section"
      id="contact-us"
      style={{ backgroundColor: "#004966" }}
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7bd79d3"
          data-id="7bd79d3"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-b432620 elementor-widget elementor-widget-contact-info"
              data-id="b432620"
              data-element_type="widget"
              data-widget_type="contact-info.default"
            >
              <div className="elementor-widget-container">
                {/* <!-- contact-area --> */}
                <section id="contact" className="contact-area">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <div className="section-title text-center mb-70">
                          <span className="sub-title">Contact Us</span>
                          <h2 className="title title">
                            <span>Contact</span> Wolflender Team
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="contact-info-wrap">
                      <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                          <div className="contact-info-item">
                            <div className="icon">
                              <span className="icon-background"></span>

                              <i aria-hidden="true" className="fas fa-star"></i>
                            </div>
                            <div className="content">
                              <p className="contact-desc">
                                company@gmail.com <br />
                                infoweb@gmail.com
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          <div className="contact-info-item">
                            <div className="icon">
                              <span className="icon-background"></span>

                              <i aria-hidden="true" className="fas fa-star"></i>
                            </div>
                            <div className="content">
                              <p className="contact-desc">
                                +84 0977425031
                                <br /> +998 765 775 34
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          <div className="contact-info-item">
                            <div className="icon">
                              <span className="icon-background"></span>

                              <i aria-hidden="true" className="fas fa-star"></i>
                            </div>
                            <div className="content">
                              <p className="contact-desc">
                                State/province/area: <br />
                                Georgia 198
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="contact-form-wrap">
                      <div
                        className="wpcf7 no-js"
                        id="wpcf7-f155-p57-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div className="screen-reader-response">
                          <p
                            role="status"
                            aria-live="polite"
                            aria-atomic="true"
                          ></p>
                          <ul></ul>
                        </div>
                        <form
                          action="/wordpress/?simply_static_page=1#wpcf7-f155-p57-o1"
                          method="post"
                          className="wpcf7-form init"
                          aria-label="Contact form"
                          noValidate="novalidate"
                          data-status="init"
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-grp">
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-name"
                                >
                                  <input
                                    size="40"
                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Enter your Name"
                                    defaultValue=""
                                    type="text"
                                    name="your-name"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-grp">
                                <span
                                  className="wpcf7-form-control-wrap"
                                  data-name="your-email"
                                >
                                  <input
                                    size="40"
                                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Enter your email"
                                    defaultValue=""
                                    type="email"
                                    name="your-email"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-grp">
                            <span
                              className="wpcf7-form-control-wrap"
                              data-name="your-message"
                            >
                              <textarea
                                cols="40"
                                rows="10"
                                className="wpcf7-form-control wpcf7-textarea"
                                aria-invalid="false"
                                placeholder="Enter your massage"
                                name="your-message"
                              ></textarea>
                            </span>
                          </div>
                          <div className="submit-btn text-center">
                            <input
                              className="wpcf7-form-control has-spinner wpcf7-submit btn"
                              type="submit"
                              defaultValue="Send Massage"
                            />
                          </div>
                          <div
                            className="wpcf7-response-output"
                            aria-hidden="true"
                          ></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
                {/* <!-- contact-area-end --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
    </main>
  );
};
export default ContactUs;
