import { useEffect } from "react";

const SiteModals = ({id,text,image,visible}) => {
  useEffect(() => {
    var myModalEl = document.querySelector('#'+id)
    var myModal = window.bootstrap.Modal.getOrCreateInstance(myModalEl) 
    if(visible == undefined ){
      myModal.show();
    }
  }, []);
  return (
    <>
      <div className="modal" id={id} tabIndex="-1">
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <img src={image}/>
              <div className="text-center" dangerouslySetInnerHTML={{__html:text}}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SiteModals;
