import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { fileURL } from "../utils/helpers";
const SiteFooter = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  return (
    <footer>
      <div
        className="footer-area footer-bg-color"
        data-bg-color="#004966"
        data-background=""
      >
        <div className="container">
          <div className="footer-scroll-wrap">
            <button className="scroll-to-target" data-target="html">
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top mt-5">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                  style={{ animationDelay: 0.2 }}
                >
                  <a className="f-logo" href="/">
                    <img src={"/static/media/logo-cropped.png"} alt="Logo" />
                  </a>

                  <div className="d-flex flex-column">
                    <a href={"https://wa.me/"+auth.whatsappNumber} target="_blank">
                      <div className="border p-2 rounded d-flex flex-row align-items-center mb-3 text-black">
                        <div className="px-2 border-end">
                          <img src={fileURL('/static/icons/whatsapp.png')} style={{height:37}}/>
                        </div>
                        <div className="d-flex flex-column ps-2">
                          <small style={{ lineHeight: "14px" }}>10 AM - 8 PM</small>
                          <span>+{auth.whatsappNumber}</span>
                        </div>
                      </div>
                    </a>
                    <a href={"tel:+"+auth.whatsappNumber} target="_blank">
                      <div className="border p-2 rounded d-flex flex-row align-items-center  mb-3  text-black">
                        <div className="px-2 border-end">
                          <i className="fa fa-phone fa-2x"></i>
                        </div>
                        <div className="d-flex flex-column ps-2">
                          <small style={{ lineHeight: "14px" }}>10 AM - 8 PM</small>
                          <span>+{auth.whatsappNumber}</span> 
                        </div>
                      </div>
                    </a>
                    <a href={auth.telegram} target="_blank">
                      <div className="border p-2 rounded d-flex flex-row align-items-center text-black">
                        <div className="px-2 border-end">
                          <i className="fa-brands fa-telegram fa-2x" style={{color:'#07a6e0'}}></i>
                        </div>
                        <div className="d-flex flex-column ps-2">
                          <small style={{ lineHeight: "14px" }}>10 AM - 8 PM</small>
                          <span>{auth.telegram}</span> 
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                  style={{ animationDelay: 0.4 }}
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <a href="/">Contact us</a>
                      </li>
                      <li>
                        <a href="/">How it Works</a>
                      </li>
                      <li>
                        <a href="/">Create</a>
                      </li>
                      <li>
                        <a href="/">Explore</a>
                      </li>
                      <li>
                        <a href="/">Terms &amp; Services</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                  style={{ animationDelay: 0.6 }}
                >
                  <h4 className="fw-title">Community</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <a href="/">Help Center</a>
                      </li>
                      <li>
                        <a href="/">Partners</a>
                      </li>
                      <li>
                        <a href="/">Suggestions</a>
                      </li>
                      <li>
                        <a href="/">Blog</a>
                      </li>
                      <li>
                        <a href="/">Newsletters</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                  style={{ animationDelay: 0.8 }}
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-content">
                    <p>
                      A new way to make the payments easy, reliable and 100%
                      secure. claritatem itamconse quat. Exerci tationulla
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a href="https://www.youtube.com/@MYGAMESKART" className="border-0">
                         <img src={fileURL('/static/icons/youtube.png')} />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=61557216415321&mibextid=ZbWKwL"  className="border-0">
                        <img src={fileURL('/static/icons/facebook.png')} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright © 2024 My GamesKart All Rights Reserved.</p>
                </div>
              </div>

              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to={"/terms-and-condition"}>
                        Terms and conditions
                      </Link>
                    </li>
                    <li>
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white d-flex flex-row fixed-bottom pt-2 pb-3  border-top d-sm-none">
        <div
          className="flex-fill  align-items-center d-flex  flex-column text-black"
          onClick={() => navigate("/")}
        >
          <img
            width="30"
            height="30"
            src={fileURL('/static/icons/home.png')}
            alt="home"
          />
          <span>Home</span>
        </div>
        <div
          className="flex-fill  align-items-center d-flex  flex-column text-black"
          onClick={() => navigate("/profile/userOrder")}
        >
          <img
            width="30"
            height="30"
            src={fileURL('/static/icons/checkout.png')}
            alt="shopping-cart-loaded"
          />
          <span>Orders</span>
        </div>
        <div
          className="flex-fill  align-items-center d-flex  flex-column text-black"
          onClick={() => navigate("/profile/userCode")}
        >
          <img
            width="30"
            height="30"
            src={fileURL('/static/icons/list.png')}
            alt="overview-pages-3"
          />
          <span>Codes</span>
        </div>
        <div
          className="flex-fill  align-items-center d-flex  flex-column text-black"
          onClick={() => {
            if (auth.userToken == null) navigate("/login");
            else navigate("/profile");
          }}
        >
          <img
            width="30"
            height="30"
            src={fileURL('/static/icons/user.png')}
            alt="person-male-skin-type-4"
          />
          <span>{auth.userToken == null ? "Login" : "Profile"}</span>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
