const getStatus = (status,uiName) => {
    if(status == '0'){
        return <p className="text-yellow">Pending</p>;
    }else if(status == '1'){
        return <p className="text-success">Completed</p>;
    }
    else if(status == '2'){
        return  <p className="text-danger">{uiName == 'order' ? 'Refund' : 'Cancelled'}</p>;
    }
}
export default getStatus;

export const isVoucher = (name) => {
    let lastChar = name.split('').pop();
    return lastChar == '.';
}

export const getPathByMaster = (mcat) => {
   if(isVoucher(mcat.cname)){
    return "/voucher/"+mcat.id;
   }
    return "/category/"+mcat.id;
}

export const fileURL= (filepath) => {
    return window.location.origin + filepath;
}