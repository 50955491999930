import React, { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../redux/features/websiteSlice";
const SimpleSlider = () => {
  const disptach = useDispatch();
  const websiteData = useSelector((state) => state.websiteData);
  useEffect(() => {
    disptach(getEvents());
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true
  };

  return (
    <div className="m-lg-5 m-xs-1">
      <Slider {...settings}>
        {websiteData.events &&
          websiteData.events.map((item, index) => {
            let url = '';
            let div = document.createElement('div');
            div.innerHTML = item.description.trim();
            let anchor = div.firstChild
            if(anchor.tagName == 'A'){
              url = anchor.href;
            }
          
            return (
            <div key={index} onClick={()=>window.open(url)}>
              <img
                src={item.image}
                alt={item.ename}
                width={"100%"}
                style={{ objectFit: "cover",maxHeight:300 }}
              />
            </div>
            );
          }
          )}
      </Slider>
    </div>
  );
};
export default SimpleSlider;
