import { userWalletListApi, validateResponse } from "../network/apiCalls";
import { useEffect, useState } from "react";
import getStatus from "../utils/helpers";
const UserWallet = () => {
  const [wallet, setWallet] = useState([]);

  const fetchWalletHistory = async () => {
    try{
      let response = await userWalletListApi();
      if (response.status == 200 && response.data.status == true) {
        let res = response.data.data.sort(function(a,b){
          if(parseInt(a.rq_id) > parseInt(b.rq_id))
            return -1;
          else if(parseInt(a.rq_id) < parseInt(b.rq_id))
            return 1;
          else 
            return 0;
        });
        setWallet(res);
      }
    }catch(e){
      validateResponse(e.response)
    }
    
  };
  useEffect(() => {
    fetchWalletHistory();
  }, []);
  return (
    <>
      <div className="row">
        <div className="mx-lg-5 rounded shadow border">
          <p className="text-black fs-4">
          <i className=" fa fa-solid fa-money-bill"></i> &nbsp;&nbsp;My Transactions
          </p>
          <div className="table-wrapper">
          <div id="table-scroll">
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <td>Txn Id</td>
                <td>Txn Status</td>
                <td>Amount</td>
                <td>DateTime</td>
                <td>SenderID</td>
                <td>SenderName</td>
              </tr>
            </thead>
            <tbody>
              {wallet.map((res,key) => {
                return (
                    <tr key={key}>
                      <td>{res.txn_id}</td>
                      <td>{getStatus(res.status)}</td>
                      <td>{res.amount}</td>
                      <td>{res.created_at}</td>
                      <td>{res.sender_id}</td>
                      <td>{res.sender_name}</td>
                    </tr>
                );
              })}
            </tbody>
          </table>
          </div></div>
        </div>
      </div>
    </>
  );
};
export default UserWallet;
