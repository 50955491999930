import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const UserProfile = () => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      <div className=" border">
        <div>
          <img
            width={"100%"}
            height={"150px"}
            style={{ objectFit: "cover" }}
            src="https://i.ibb.co/f0wGK10/coer.jpg"
            alt=""
          />
        </div>
        <div className="m-3 m-md-5">
          <div className="row" style={{ marginTop: "-87px", position: "relative" }}>
            <div className="col-6">
              <img
                width={"100px"}
                src={auth.userInfo.image != "" ? auth.userInfo.image : (auth.userInfo?.photoURL ? auth.userInfo.photoURL : 'https://cdn-icons-png.flaticon.com/512/3135/3135715.png') }
              />
            </div>
            
          </div>
          <div className="d-flex flex-column fs-4 mt-3 mt-md-5">
            <p className="text-black fw-bold fs-5">
              User ID: &nbsp;&nbsp;
              <span className="text-primary fs-6">{auth.userInfo.code}</span>
            </p>
            <p className="text-black fw-bold fs-5">
              Name: &nbsp;&nbsp;
              <span className="text-primary fs-6">{auth.userInfo.name}</span>
            </p>
            <p className="text-black fw-bold fs-5">
              Email: &nbsp;&nbsp;
              <span className="text-primary fs-6">{auth.userInfo.email}</span>
            </p>
            {/* <p className="text-black fw-bold fs-5">
              Phone: &nbsp;&nbsp;
              <span className="text-primary fs-6">{auth.userInfo.mobile_no}</span>
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProfile;
