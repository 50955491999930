import { useEffect } from "react";
import $ from "jquery";
import Slider from "react-slick";
import Countdown from "react-countdown-simple";
import { Link } from "react-router-dom";
var mCustomScrollbar = require("malihu-custom-scrollbar-plugin");
// import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'

// require('jquery-mousewheel')

const AboutUs = () => {
  var sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <main className="main-area mb-5">
      <div
        data-elementor-type="wp-page"
        data-elementor-id="57"
        className="elementor elementor-57"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e234df9 elementor-section-full_width choose-area elementor-section-height-default elementor-section-height-default"
          data-id="e234df9"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f1c6e5e"
              data-id="f1c6e5e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-a7884f5 elementor-widget elementor-widget-tp-heading"
                  data-id="a7884f5"
                  data-element_type="widget"
                  data-widget_type="tp-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="section-title">
                      <span className="sub-title">About Us</span>
                      <h2 className="title">
                       Who We <span>Are</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4f7283c elementor-widget elementor-widget-features"
                  data-id="4f7283c"
                  data-element_type="widget"
                  data-widget_type="features.default"
                >
                  <div className="elementor-widget-container">
                    <div className="container">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pt-5 elementor-section elementor-top-section elementor-element elementor-element-f4ac265 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="f4ac265"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6ed8b1e"
              data-id="6ed8b1e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-0561d11 elementor-widget elementor-widget-brand"
                  data-id="0561d11"
                  data-element_type="widget"
                  data-widget_type="brand.default"
                >
                  <div className="elementor-widget-container">
                    {/* <!-- partner-area --> */}
                    <div className="partner-area">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            <div className="section-title text-center mb-10">
                              <span className="sub-title">
                                We support bitcoin currency
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="partner-wrap">
                              <ul>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img01.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img02.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img03.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img04.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img05.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img06.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img07.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img08.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img09.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <img
                                      decoding="async"
                                      src="./wp-content/uploads/2023/03/partner_img10.png"
                                      alt=""
                                    />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- partner-area-end --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e234df9 elementor-section-full_width choose-area elementor-section-height-default elementor-section-height-default"
          data-id="e234df9"
          data-element_type="section"
          data-settings='{"background_background":"classNameic"}'
          style={{ backgrounColor: "#004966" }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f1c6e5e"
              data-id="f1c6e5e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-a7884f5 elementor-widget elementor-widget-tp-heading"
                  data-id="a7884f5"
                  data-element_type="widget"
                  data-widget_type="tp-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="section-title">
                      <span className="sub-title">why Choose us</span>
                      <h2 className="title">
                        Why choose <span>Wolflender</span>
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4f7283c elementor-widget elementor-widget-features"
                  data-id="4f7283c"
                  data-element_type="widget"
                  data-widget_type="features.default"
                >
                  <div className="elementor-widget-container">
                    <div className="container">
                      <div className="row choose-active">
                        <Slider {...sliderSettings}>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon01.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Mobile payment make easy
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon02.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Lifetime free transaction
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon03.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Protect the <br /> identity
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon04.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Security &amp; control over money
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="choose-item">
                              <div className="choose-icon">
                                <img
                                  decoding="async"
                                  className="light"
                                  src="https://themedox.com/bigtech/wp-content/uploads/2023/03/choose_icon02.svg"
                                  alt=""
                                />
                              </div>
                              <div className="choose-content">
                                <h2 className="title">
                                  Lifetime free transaction
                                </h2>
                                <p>
                                  Add new trending and rare artwork to your
                                  collection.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Slider>
                      </div>
                      <div className="slide-progress" role="progressbar">
                        <span className="slider__label sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
export default AboutUs;
