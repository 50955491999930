import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {  logout, updateUser } from "../redux/features/authSlice";
import { toast } from "react-toastify";

const UserSecurity = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state)=>state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
        data.email = auth.userInfo.email;
        data.name = auth.userInfo.name;
        data.mobile_no = auth.userInfo.mobile_no;
        const result = await dispatch(updateUser(data)).unwrap();
        toast.success('Password Change Successfully');
        dispatch(logout())
      } catch (error) {
        toast.error(error);
      }
  };
  return (
    <>
      {" "}
      <div className="row">
        <div className="mx-lg-5 rounded shadow border">
          <form action="" className="row g-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
              <label className="text-black">
                New Password
                <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                {...register("password", {
                  required: {value:true,message:"Required Field"},
                  minLength:{value:5,message:"Min length must be five"},

                })}
              />
              <p className="text-danger m-0">{errors.password?.message}</p>
            </div>
            <div className="col-12">
              <label className="text-black">
                Confirm New Password
                <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                {...register("new_password", {
                  required: {value:true,message:"Required Field."},
                  minLength:{value:5,message:"Min length must be five"},
                  validate: (value,formValues) => {
                        if(value != formValues.password){
                            return 'Does not match with password';
                        }
                    
                  }
                })}
              />
               <p className="text-danger m-0">{errors.new_password?.message}</p>
            </div>
            <div className="col-lg-12 d-grid my-2">
              <button className="btn btn-primary">Change Password</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserSecurity;