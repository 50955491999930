const CategoryBox = ({data, handleClick,isSelected}) => {
  return (
    
    <div
      className={"col-lg-2 col-6 mt-3 p-2 p-lg-2" + (isSelected?" selected-box":"")}
      onClick={() => handleClick(data)}
    >
      <div className="bg-white shadow p-3 rounded text-center">
        <img
          className="w-full rounded"
          src={data.image}
          title="image"
          style={{ objectFit: "cover", width: "80%" }}
        />
        <div className="flex flex-col gap-y-1 pt-3">
          <p className="text-black mb-0">{data.cname}</p>
        </div>
      </div>
    </div>
  );
};
export default CategoryBox