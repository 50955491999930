import { Link } from "react-router-dom";
const TermsConditionPage = () => {
    return(
        <>
        <div className="text-center p-5 ">
            <h3 className="text-white">Terms & Conditions</h3>
            <div className="">
                
            </div>
            <p>
            Welcome to [Your Company Name]! These terms and conditions ("Terms") govern your use of our website, products, and services. Please read these Terms carefully before accessing or using any part of our platform. By accessing or using our platform, you agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our platform.
            </p>
            <ul>
                <li>
                    Introduction
                    <p>
                        <ul>
                            <li>a. [Your Company Name] is an online platform that provides [brief description of your products/services].</li>
                            <li>b. By using our platform, you agree to comply with these Terms and all applicable laws and regulations.</li>
                        </ul>
                    </p>
                </li>
            </ul>
        </div>
        </>
    )
}
export default TermsConditionPage