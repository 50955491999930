import React, {useState} from 'react';
import { Link } from 'react-router-dom';

const MenuDropdown = ({ title, items ,styles}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleMouseOver = () => {
      setShowDropdown(true);
    };
    const handleMouseLeave = () => {
        setShowDropdown(false);
      };
    return (
      <li className="nav-item dropdown" 
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={()=>setShowDropdown(!showDropdown)}
      >
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded={showDropdown ? "true" : "false"}
        >
          {title}
        </a>
        <div style={styles ?? {...styles}} className={`dropdown-menu ${showDropdown ? "show" : ""}`} aria-labelledby="navbarDropdown">
        {items.map((item, index) => (
          <React.Fragment key={index}>
              <Link className="dropdown-item" to={item.path}>
                {item.image && <img src={item.image} style={{height:'40px', width:'40px'}} className='rounded-pill'/>} &nbsp; {item.title}
              </Link>
          </React.Fragment>
        ))}
        </div>
      </li>
    );
};

export default MenuDropdown;