import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMasterCategoryApi,getEventsApi } from "../../network/apiCalls";
const initialState = {
  loading: false,
  errorMessage: "",
  events: [],
  coupans: [],
  masterCategory: [],
  subCategory: [],
  shopStatus:null
};

export const getMasterCategory = createAsyncThunk(
  "websiteData/masterCategory",
  async (_, thunkAPI) => {
    try {
      let response = await getMasterCategoryApi();
      if (response.data.status == true) return response.data.data;
      return thunkAPI.rejectWithValue(response.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
export const getEvents = createAsyncThunk(
  "websiteData/getEvents",
  async (_, thunkAPI) => {
    try {
      let response = await getEventsApi();
      if (response.data.status == true) return response.data.data;
      return thunkAPI.rejectWithValue(response.data.message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      } else {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const websiteData = createSlice({
  name: "websiteData",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMasterCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(getMasterCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.masterCategory = action.payload;
    });

    //getEvents
    builder.addCase(getEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvents.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    });
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action.payload;
    });
  },
});

const websiteReducer = websiteData.reducer;

export const { setUsername } = websiteData.actions;
export default websiteReducer;
