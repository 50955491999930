import { useEffect, useState } from "react";
import SimpleSlider from "../components/SimpleSlider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import CategoryBox from "../components/CategoryBox";
import { getPathByMaster, isVoucher } from "../utils/helpers";

const HomePage = (props) => {  
  const websiteData = useSelector((state) => state.websiteData);
  const navigate = useNavigate();
  const handleClick = (mCat) =>{
      navigate(getPathByMaster(mCat)) 
  }
  let ary = [];
  const type = props.type;
  let list = websiteData.masterCategory.reduce((ac,cv)=>{
          if(Object.keys(ac).includes(cv.section)){
              ac[cv.section].push(cv)
          }else{
              ac[cv.section] = [cv]
          }
          return ac;
      },{});
  return (
    <>
      <SimpleSlider />
        {list && Object.keys(list).map((title ,ind)=>{
          return (
            <div key={ind} className="row mx-lg-5 mx-2 mt-3">
              <div className="px-2 display-6 text-black">{title}</div>
              {list[title].map((item, index) => {
                return (
                 <CategoryBox key={index} data={item} handleClick={handleClick}/>
                );
              })}
            </div>
          )
        })}

    </>
  );
};
export default HomePage;
