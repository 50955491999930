// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB3p7H7_gZIxI_9rTqqLhgxx7lYEXknZKc",
  authDomain: "mygameskart-d1ab2.firebaseapp.com",
  projectId: "mygameskart-d1ab2",
  storageBucket: "mygameskart-d1ab2.appspot.com",
  messagingSenderId: "321764405093",
  appId: "1:321764405093:web:4cc3b1cf312bf6bffc3b8c",
  measurementId: "G-LNRH0KNWE4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const googleAuth = getAuth(app)
const GoogleProvider = new GoogleAuthProvider()
GoogleProvider.setCustomParameters({
  prompt:'select_account'
})
export {googleAuth, GoogleProvider};
