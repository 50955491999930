import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {  updateUser } from "../redux/features/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { validateResponse } from "../network/apiCalls";

const UserEdit = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state)=>state.auth);
  const navigate = useNavigate();
  const [passView, setPassView] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
        console.log(data);
        data.email = auth.userInfo.email;
        const result = await dispatch(updateUser(data)).unwrap();
        toast.success('Updated Successfully');
      } catch (error) {
        toast.error(error);       
      }
  };
  return (
    <>
      {" "}
      <div className="row">
        <div className="mx-0 mx-md-5  rounded shadow border">
          <form action="" className="row g-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
              <label className="text-black">
                Your Name
                <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...register("name", {
                  required: {value:true,message:"Required Field"},
                })}
                defaultValue={auth.userInfo.name}
              />
              <p className="text-danger m-0">{errors.name?.message}</p>
            </div>
            <div className="col-12">
              <label className="text-black">
                Your Phone
                <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...register("mobile_no", {
                  required: {value:true,message:"Required Field."},
                })}
                defaultValue={auth.userInfo.mobile_no}
              />
               <p className="text-danger m-0">{errors.mobile_no?.message}</p>
            </div>
            <div className="col-lg-12 d-grid my-2">
              <button className="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default UserEdit;
